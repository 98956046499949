import { render, staticRenderFns } from "./photoList.vue?vue&type=template&id=fdaea8d4&scoped=true&"
import script from "./photoList.vue?vue&type=script&lang=js&"
export * from "./photoList.vue?vue&type=script&lang=js&"
import style0 from "./photoList.vue?vue&type=style&index=0&id=fdaea8d4&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fdaea8d4",
  null
  
)

export default component.exports